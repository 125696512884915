var options = {
    ariaLabel: "Sidebar Navigation",
    orientation: "vertical",
    useHover: false,
    useResponsiveMenu: false,
    activeClass: "bux-sidebar-nav__link--active",
    expandToActive: true,
};

const navMenuCheck = document.querySelector(".bux-sidebar-nav");
if (navMenuCheck) {
    if (typeof disclosureNav != "undefined") {
        var sidebarNav = new disclosureNav("#sidebar-nav", options);
    }
}
